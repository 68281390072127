import { AudienceType, ReactSelectOptionType } from "./../Structures";
import {
    AUDIENCE_ANY,
    EMOJI_REGEX,
    EMOJI_COUNT,
    SPECIAL_CHARS_REGEX,
    SPECIAL_CHAR_COUNT,
    ZERO_WIDTH_JOINER,
    MAX_STRING_LENGTH,
    MATCH_ALL_LINE_BREAKS_REGEXP,
    MATCH_HTML_LINE_BREAKS_REGEXP,
    JOINERS_ENTITIES,
    SPACES_ENTITIES,
} from "../Constants";

import { Widget, AppLocation, Utms } from "../Structures";

import { TileParams } from "../Structures";

export const createUrlForWidget = (url: string): string => {
    if (!url || url.length === 0) {
        return "";
    }

    let u = url;

    if (u.indexOf("https://") >= 0) u = u.slice(8, u.length);

    if (u.indexOf("http://") >= 0) u = u.slice(7, u.length);

    if (u.indexOf("vk.cc/") === 0) {
        let key = u.replace("vk.cc/", "");
        return `https://vk.com/cc?act=go&key=${key}`;
    } else {
        return url;
    }
};

export const widgetHasUnpublishedState = (widget: Widget): boolean => {
    let targetWidget = { ...widget };

    if (
        !targetWidget.last_published_state ||
        typeof targetWidget.last_published_state !== "string"
    )
        return false;

    try {
        let lastState = JSON.parse(widget.last_published_state);

        if (!lastState.code) return false;

        let serializedCurrentState = JSON.stringify({
            code: widget.code,
            audience: widget.audience,
        });

        return targetWidget.last_published_state !== serializedCurrentState;
    } catch (e) {
        return false;
    }
};

export const getWidgetsWithLastPublishedState = (widgets: Widget[]) => {
    return widgets.map((item) => {
        if (widgetHasUnpublishedState(item)) {
            let itemInPublishedState = { ...item };
            try {
                let lastPublishedState = JSON.parse(item.last_published_state);
                itemInPublishedState.code = lastPublishedState.code;
                itemInPublishedState.audience = lastPublishedState.audience;
                return itemInPublishedState;
            } catch (e) {
                console.log(e);
                return item;
            }
        } else {
            return item;
        }
    });
};

export const widgetHasAudience = (widget: Widget): boolean => {
    const audience = { ...widget.audience };

    if (Object.keys(audience).length === 0 || audience.length === 0)
        return false;

    // Костыль
    if (
        (audience.sex && audience.sex !== AUDIENCE_ANY) ||
        (audience.ageFrom && audience.ageFrom !== AUDIENCE_ANY) ||
        (audience.ageTo && audience.ageTo !== AUDIENCE_ANY) ||
        (audience.names && audience.names !== AUDIENCE_ANY) ||
        (audience.platform && audience.platform !== "any" && audience.platform.length > 0) ||
        (audience.bDay && audience.bDay !== AUDIENCE_ANY) ||
        (audience.surnames && audience.surnames !== AUDIENCE_ANY) ||
        (audience.userCoupleInterests &&
            audience.userCoupleInterests !== AUDIENCE_ANY) ||
        (audience.userInterests && audience.userInterests !== AUDIENCE_ANY) ||
        (audience.communityIn && audience.communityIn.length > 0) ||
        (audience.communityOut && audience.communityOut.length > 0) ||
        (audience.users && audience.users.length > 0) ||
        (audience.currentCity && audience.currentCity.length > 0) ||
        (audience.currentCountry && audience.currentCountry.length > 0) ||
        (audience.relation && audience.relation.length > 0)
    ) {
        return true;
    } else {
        return false;
    }
};

const isEmoji = (char) => char.match(EMOJI_REGEX);
const isZWJ = (char) => char.codePointAt(0).toString(16) === ZERO_WIDTH_JOINER;
const isSpecialChar = (char) => char.match(SPECIAL_CHARS_REGEX);

/**
 * Удаление или замена ненужных сущностей из строки в том числе
 * Нельзя полностью удалить ZWJ так как они используются в Emoji
 * @param string
 */
export const trimUnnecessaryCharacters = (string: string): string => {
    let hasEmojis = isEmoji(string);
    string = string.replace(/[\u200c]/gi, ""); // Удалим все zero width non joiner
    string = string.replace(SPACES_ENTITIES, " "); // Удалим все другие виды пробелов

    if (!hasEmojis) {
        // Если строка не сожержит emoji
        string = string.replace(JOINERS_ENTITIES, ""); // Удалим все zwj
    }

    return string.trim();
};

/**
 * Подсчет длины строки так, как ее раздекодит VK при сохраненеии виджета
 * @param string
 */
export const countStringLength = (string: String): Number => {
    if (typeof string !== "string") {
        console.warn(
            `WARN: countStringLength - inappropriate data type ${typeof string}`
        );
        return 0;
    }

    string = string.replace(/(\[gender\]|\[\/gender\])/gi, ""); // Удалим маркеры переменной по полу

    const chars = Array.from(string);
    let decodeStringLength = 0;

    chars.forEach((char) => {
        if (isZWJ(char)) {
            decodeStringLength += 5;
        } else if (char.match(/[\u200c]/gi)) {
            decodeStringLength += 6;
        } else if (char.match(/[\u2003]/gi)) {
            decodeStringLength += 6;
        } else if (char.match(/[\u2009]/gi)) {
            decodeStringLength += 8;
        } else if (isSpecialChar(char)) {
            decodeStringLength += SPECIAL_CHAR_COUNT;
        } else if (isEmoji(char)) {
            decodeStringLength += EMOJI_COUNT;
        } else {
            decodeStringLength += char.length;
        }
    });

    return decodeStringLength;
};

/**
 * Обрезать строку для VK в соответствии с тем,
 * как строки воспринимаются на его стороне
 * @param string
 */
export const cropStringForVk = (
    string: string,
    maxLength: number = MAX_STRING_LENGTH
): String => {
    string = trimUnnecessaryCharacters(string);

    const decodedStringLength = countStringLength(string);

    if (decodedStringLength <= maxLength) {
        return string;
    }

    let newString = string;

    while (
        countStringLength(newString) > maxLength - 4 ||
        isEmoji(newString[newString.length - 1]) ||
        isZWJ(newString[newString.length - 1])
    ) {
        newString = newString.slice(0, -1);
    }

    return `${newString.trim()}...`;
};

export const scopeReplace = (scope: string, newScope: string = "") => {
    let arr = decodeURIComponent(scope).split(",");
    if (!!newScope) arr.push(newScope);
    if (arr.indexOf("notify") >= 0) arr.splice(arr.indexOf("notify"), 1);
    return arr.join(",");
};

/**
 * Глубокая заморозка объекта
 * @param obj
 */
export const deepFreeze = (obj) => {
    // Получаем имена свойств из объекта obj
    var propNames = Object.getOwnPropertyNames(obj);

    // Замораживаем свойства для заморозки самого объекта
    propNames.forEach(function (name) {
        var prop = obj[name];

        // Заморозка свойства prop, если оно объект
        if (typeof prop == "object" && prop !== null) deepFreeze(prop);
    });

    // Заморозить сам объект obj (ничего не произойдёт, если он уже заморожен)
    return Object.freeze(obj);
};

export const setHash = (data: AppLocation) => {
    let hash = "";

    if (data.list) {
        hash += `${data.list}|`;
    }

    if (data.view) {
        hash += `${data.view}|`;
    }

    if (data.panel) {
        hash += `${data.panel}|`;
    }

    if (data.widget_id) {
        hash += `${data.widget_id}|`;
    }

    if (data.page_id) {
        hash += `${data.page_id}|`;
    }

    if (data.type && data.type_api) {
        hash += `${data.type}-${data.type_api}`;
    }

    if (data.tab) {
        hash += `${data.tab}|`;
    }

    if (data.s_id) {
        hash += `${data.s_id}|`;
    }

    if (data.block_type) {
        hash += `${data.block_type}|`;
    }

    if (data.block_id) {
        hash += `${data.block_id}|`;
    }

    window.location.hash = hash;
    return hash;
};

/**
 *
 * @param {string} hash
 */
export const parseHash = (hash) => {
    let res: AppLocation = {
        list: "subscriptions",
        view: "main",
        panel: "",
        widget_id: "",
    };

    if (!hash) return res;

    let clearValue = hash.substring(1);
    let parts = clearValue.split("|");

    if (parts[0]) {
        if (parts[0].indexOf("s=") >= 0) {
            res.s_id = parts[0].replace("s=", "");
            return res;
        }

        if (parts[0].indexOf("d=") >= 0 || parts[0] === "unsubscribe") {
            res.list = "mysubscriptions";
            return res;
        }

        if (
            [
                "subscriptions",
                "mysubscriptions",
                "widgets",
                "app-settings",
                "pages",
            ].indexOf(parts[0]) >= 0
        ) {
            res.list = parts[0];
        } else {
            res.list = "subscriptions";
        }
    }

    if (parts[1]) {
        res.view = parts[1];
        if (parts[1] === "initial-settings") {
            res.view = "initial-settings";
            return res;
        }
    }

    if (parts[2]) {
        res.panel = parts[2];
    }

    if (parts[3]) {
        res.widget_id = parts[3];
    }

    if (parts[1] === "widgets" && parts[2] === "name") {
        delete res.widget_id;
        res.type = parts[3].split("-")[0];
        res.type_api = parts[3].split("-")[1];
    }

    if (
        (parts[0] === "subscriptions" || parts[0] === "mysubscriptions") &&
        parts[1]
    ) {
        delete res.view;
        res.s_id = parts[1];
    }

    if (parts[4] && parts[2] !== "blocks-edit") {
        res.tab = parts[4];
    }

    if (parts[0] === "pages" && parts[3]) {
        delete res.widget_id;
        res.page_id = parts[3];
    }

    if (parts[2] === "blocks-edit" && parts[4]) {
        res.block_id = parts[4];
    }

    if (parts[2] === "blocks-items" && parts[3]) {
        delete res.page_id;
        delete res.widget_id;
        res.block_type = parts[3];
    }

    return res;
};

/**
 * Проверка - является ли строка string корректным именем screen_name
 * @param string
 * @TODO - обновить проверку по гайду - https://vk.com/faq18038
 */
export const isScreenName = (string: string): boolean => {
    // Allow only latin characters with lodash
    return /^[a-z]+[a-z_]+/gim.test(string.toLowerCase()) && string.length >= 4;
};

/**
 * Проверка - является ли строка валидной внутренней ссылкой VK
 * @param string
 */
export const isValidUrl = (string: string): boolean => {
    return /^(?:https?:\/\/)?(?:m.)?vk.(com|me)\/([^\s]+)/gim.test(
        string.toLowerCase()
    );
};

/**
 * Проверка - является ли строка валидной ссылкой
 * @param value
 */
export const isValidLink = (value: string): boolean => {
    // Возможно стоит сделать необязательной проверку на http\https
    return /^((?:(?:(?:https?|ftp):)?\/\/))?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        value
    );
};

/**
 * Распарсит из ссылки короткое имя пользователя, группы или приложения. Например, apiclub, andrew или rules_of_war.
 * @param url
 * @returns {string} - полный screen_name, если удалось получить. Пустую строку, если данные не корректны
 */
export const getScreenNameFromUrl = (url: string) => {
    if (!isValidUrl(url)) {
        console.warn(
            `GetScreenNameFromUrl: URL - ${url} - is not valid vk url`
        );
        return "";
    }
    let screen_name = url.replace(/^(?:https?:\/\/)?(?:m.)?vk.com\//gim, "");

    if (!isScreenName(screen_name)) {
        console.warn(
            `GetScreenNameFromUrl: parsed screen name - ${screen_name} - is not valid vk screen name`
        );
        return "";
    }

    return screen_name;
};

/**
 * Получить id сущности ВК из ссылки
 * - Пользователь
 * - Паблик
 * - Группа
 * - Событие
 * @param url string
 */
export const getVkIdFromUrl = (
    url: string
): { type: string; value: number } => {
    const result = { type: "", value: 0 };
    const matches =
        /^(?:https?:\/\/)?(?:m.)?vk.com\/(id|public|club|event)(\d{1,20})/gi.exec(
            url
        );
    if (matches) {
        result.type = matches[1];
        result.value = parseInt(matches[2]);
    }
    return result;
};

export const dateToISOFormat = (dateTime: string): string => {
    try {
        const parts = dateTime.split(" ");
        const date = parts[0].split(".").reverse().join("-");
        const time = parts[1];

        // Если не удалось распарсить время или дата указана не полностью
        if (!time || date.split("-").length < 3) {
            return "";
        }

        return `${date}T${time}:00`;
    } catch (e) {
        console.warn(
            `DateToIsoFormat: Wrong dateTime format - ${typeof dateTime}`
        );
        return "";
    }
};

export const daysBetween = (startDate: Date, endDate: Date): number => {
    if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
        console.warn(`DaysBetween: date wrong format`);
        return 0;
    }

    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;

    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
    );
    const end = Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
    );

    // so it's safe to divide by 24 hours
    return (start - end) / oneDay;
};

/**
 * Конвертирует целое число (количество дней) в строку вида "1 день", "38 дней"
 * @param days number
 */
export const getDaysCount = (days: number): string => {
    if (typeof days !== "number") {
        console.warn(`GetDaysCount helper: wrong days format - ${typeof days}`);
        return "";
    }

    // Возможно, стоит обработать отрицательные цисла
    if (days < 0) return "";

    if (days === 1 || (days % 10 === 1 && days !== 11)) {
        return `${days} день`;
    } else if (days >= 12 && days <= 14) {
        return `${days} дней`;
    } else if ((days >= 2 && days <= 4) || (days % 10 >= 2 && days % 10 <= 4)) {
        return `${days} дня`;
    } else {
        return `${days} дней`;
    }
};

export const isIOS = () => {
    //@ts-ignore
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        if (!!window.indexedDB) {
            return true;
        }
        if (!!window.SpeechSynthesisUtterance) {
            return true;
        }
        if (!!window.matchMedia) {
            return true;
        }
        if (!!window.history && "pushState" in window.history) {
            return true;
        }
        return true;
    }
    return false;
};

export const isVkSubscriptionUrl = (url: string): boolean => {
    if (!isValidUrl(url)) {
        return false;
    }
    return /^(?:https?:\/\/)?(?:m.)?vk.com\/([^\s]+)#s=\d{1,}/gm.test(url);
};

export const isVkPageUrl = (url: string): boolean => {
    if (!isValidUrl(url)) {
        return false;
    }

    return /^(?:https?:\/\/)?(?:m.)?vk.com\/([^\s]+)#page=\w{1,}/gm.test(url);
};

export const parseSubscriptionIdFromUrl = (url: string): number | boolean => {
    const matches =
        /^(?:https?:\/\/)?(?:m.)?vk.com\/([^\s]+)#s=(\d{1,})/gm.exec(url);
    if (!matches || !matches[2]) {
        return false;
    }
    return parseInt(matches[2], 10);
};

export const parsePageIdFromUrl = (url: string): string | boolean => {
    const matches =
        /^(?:https?:\/\/)?(?:m.)?vk.com\/([^\s]+)#page=(\w{1,})/gm.exec(url);

    if (!matches || !matches[2]) {
        return false;
    }

    return matches[2];
};

export const widgetsImagesSetByIds = (tiles: Array<TileParams>): boolean => {
    if (!tiles) return false;
    const res = tiles.filter((t) => {
        if (!t.icon_id) return false;
        return /^id(-)?\d{1,}$/gm.test(t.icon_id);
    });

    return res.length > 0;
};

export const copyToClipboard = (value: string, callback: Function) => {
    let textarea;
    let result;

    try {
        textarea = document.createElement("textarea");
        textarea.setAttribute("readonly", true);
        textarea.setAttribute("contenteditable", true);
        textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = value;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand("copy");
    } catch (err) {
        console.error(err);
        result = null;
    } finally {
        document.body.removeChild(textarea);
    }

    // manual copy fallback using prompt
    if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
        const copyHotkey = isMac ? "⌘C" : "CTRL+C";
        result = prompt(`Нажмите ${copyHotkey}`, value); // eslint-disable-line no-alert
        if (!result) {
            return false;
        }
    }

    if (callback && typeof callback === "function") {
        callback();
    }

    return true;
};

export const replaceLineBreaks = (text) => {
    if (!text) return "";
    return text.replace(MATCH_ALL_LINE_BREAKS_REGEXP, ". ");
};

export const replaceHtmlBreaks = (text) => {
    if (!text) return "";
    return text.replace(MATCH_HTML_LINE_BREAKS_REGEXP, "");
};

export const wait = (time = 1000) => {
    return new Promise((res, rej) => {
        setTimeout(() => {
            //@ts-ignore
            res();
        }, time);
    });
};

/**
 * Сгенерирует массив с диапазоном чисел от from до to
 * @param from
 * @param to
 */
export const range = (from: number, to: number): number[] => {
    let res = [];

    for (let i = from; i <= to; i++) {
        res.push(i);
    }

    return res;
};

/**
 * Вставит `value` в строку `string` начиная с позиции `poistion`
 * @param str
 * @param value
 * @param position
 */
export const insertInToString = (
    str: string,
    value: string,
    position: number = 0
): string => {
    return [str.slice(0, position), value, str.slice(position)].join("");
};

/**
 * Определит итп иконки для виджета
 * @param iconId
 */
export const getIconIdType = (iconId: string) => {
    if (!iconId) return "current-user";

    if (
        ["current-user", "current-user-friend", "current-user-partner"].indexOf(
            iconId
        ) > -1
    ) {
        return iconId;
    }

    if (iconId.indexOf("id-") > -1) {
        return "public";
    }

    if (iconId.indexOf("id") > -1) {
        return "user";
    }

    return "current-user";
};

/**
 * Распарсит id иконки виджета
 * Вернет объект
 * {
 *  type: 'user' | 'public'
 *  id: string
 * }
 * @param iconId
 */
export const parseIconId = (iconId: string) => {
    if (typeof iconId !== "string") {
        return { type: "user", id: "" };
    }

    let id = iconId.replace("id", "");

    if (id[0] === "-") {
        // Если сообщество
        return { type: "public", id: id.replace("-", "") };
    } else if (!isNaN(parseInt(id[0]))) {
        // Если пользователь
        return { type: "user", id: id };
    } else {
        return { type: "user", id: "" };
    }
};

/**
 * Вернет uri decoded redirect url с внешнего ресурса в зависимости от того, с какой платформы на внешний ресурс перешли
 * Например successUrl при оплате через ЯД
 *
 * @param url
 * @param platform
 */

export const createExternalRedirectUrl = (
    url: string,
    platform: string = "decktop_web"
): string => {
    const isVk = /^(?:https?:\/\/)?(?:m.)?vk\.com/im.test(url);

    let res = "";

    // Если ссылка на другой ресурс кроме вк - оставим ее как есть
    if (false === isVk) {
        res = url;
    }
    // Если ссылка формируется на мобильном устройстве внутри приложения VK
    else if (["mobile_android", "mobile_iphone"].indexOf(platform) >= 0) {
        res = `https://senler.ru/link?u=${url}`;
    } else {
        res = url;
    }

    return encodeURIComponent(res);
};

export const getAndroidVersion = (): number => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const match = userAgent.match(/android\s([0-9]{1,2})/i);

    return Number(match[1]);
};

export const getDecodedWithSpecialCharsString = (text: string): string => {
    const parser = new DOMParser();
    return parser.parseFromString(text, "text/html").body.textContent;
};

export const clone = (
    o, // The only argument passed by user: object to clone
    h = [] // Cache (organized as array: [key,value,key,value,...])
) => {
    var i,
        r,
        x, // Property indexer, result, temporary variable
        t = [Array, Date, Number, String, Boolean], // Types to treat in a special way
        s = Object.prototype.toString; // Shortcut to Object.prototype.toString
    h = h || []; // If cache is not created yet, create it!
    for (
        i = 0;
        i < h.length;
        i += 2 // Search cache for our object
    )
        if (o === h[i]) r = h[i + 1];
    if (!r && o && typeof o == "object") {
        // Clone o if it is uncached object and not null
        r = {}; // Default result template: plain hash
        for (
            i = 0;
            i < t.length;
            i++ // To handle multiframe environment, search for type by
        )
            if (
                s.call(o) ==
                s.call(
                    //   comparing Object.prototype.toString's of our object
                    (x = new t[i](o))
                )
            )
                //   and new object x created with the constructor t[i]
                // Notice that it will create new Date(o), new String(o)
                //   which is good and new Array(o) which is bad
                r = i ? x : []; // If i==0, t==Array. We need to recreate it. Else use x
        h.push(o, r); // Add object to cache before (!) making recursive call
        for (i in o) // Just copy properties recoursively
            if (h.hasOwnProperty.call(o, i))
                // As o might have key 'hasOwnProperty', use something
                r[i] = clone(o[i], h); //   we defined right instead
    }
    return r || o; // Return r if it was found in cache or built in if(){}
};

/**
 * Проверяет является ли строка iframe вк-видео валидной
 * например - <iframe src="https://vk.com/video_ext.php?oid=-79158524&id=456239100&hash=16a8064da43ce0ad" width="640" height="360" frameborder="0" allowfullscreen></iframe> является валидной строкой
 *
 * @param {string} iframeStr
 * @returns {boolean}
 */
export const isVkVideoIframe = (iframeStr: string): boolean => {
    return /<iframe[^>]*src\s*=\s*"?https?:\/\/[^\s"\/]*vk.com(?:\/[^\s"]*)?"?[^>]*>.*?<\/iframe>/gi.test(
        iframeStr
    );
};

/**
 * Проверяет на валидность итоговый url из iframe
 * например - https://vk.com/video_ext.php?oid=-79158524&id=456239100&hash=16a8064da43ce0ad является валидным
 *
 * @param {string} url
 * @returns {boolean}
 */
export function isValidVkVideoUrl(url: string): boolean {
    return /https:\/\/(?:www\.|)vk\.com\/video_ext\.php.*/gi.test(url);
}

/**
 * Возвращает src из строки
 * например - src="https://vk.com/video_ext.php?oid=-79158524&id=456239100&hash=16a8064da43ce0ad"
 *
 * @param {string} str
 * @returns {string}
 */
export const getSrc = (str: string): string => {
    const match = str.match(/src="([^"]+)"/);

    return match ? match[1] : "";
};

/**
 * Заменяет переменную на replacer
 * @param {string} str
 * @param {string} variable
 * @param {string} replacer
 * @returns {string}
 */
export const replaceVariable = (
    str: string,
    variable: string,
    replacer: string
): string => {
    return str.replace(new RegExp(`${variable}`, "gi"), replacer);
};

/**
 * Возвращает строку с параметрами utm-меток
 * @param {object} utmsObject
 * @returns {string}
 */
export const getUtmParamsString = (utmsObject: Utms): string => {
    return Object.keys(utmsObject)
        .map((key) => {
            if (utmsObject[key]) {
                return key + "=" + utmsObject[key];
            }
        })
        .filter((it) => it)
        .join("&");
};

/**
 * Возвращает значение odr из параметров запуска vk приложения ( в url )
 *
 * @returns {number} - вернет 0 || 1
 */
export const getOdrParam = (windowLocationSearch: string): number => {
    let a = windowLocationSearch;
    let odrValue = 0;
    let params = [];

    a = a.substring(1);

    if (!a) {
        return odrValue;
    }

    params = a.split("&");

    for (let i = 0; i < params.length; i++) {
        let c = params[i].split("=");

        if (c[0] === "odr_enabled") {
            odrValue = parseInt(c[1], 10);
        }
    }

    return odrValue;
};

/**
 * Функия, которая проверяет является ли значение цвета
 * в hex формате
 *
 * @param {string} color
 * @returns {boolean}
 */
export const isHex = (color: string): boolean => {
    return /^#([0-9a-f]{3}){1,2}$/i.test(color);
};

/**
 * Функция, которая проверяет есть ли у аудитории
 * блока поля заданные пользователем и неравные
 * дефолтным значениям
 *
 * @param {BlockData} block - данные блока
 * @returns {boolean}
 */
export const blockHasAudience = (blockAudienceData: AudienceType): boolean => {
    const audienceExistAndNonDefaultFields = Object.keys(
        blockAudienceData
    ).filter((item: string) => {
        if (
            item in blockAudienceData &&
            blockAudienceData[item] !== AUDIENCE_ANY
        ) {
            if (Array.isArray(blockAudienceData[item])) {
                return blockAudienceData[item].length > 0;
            }

            if (item === "variable_global") {
                return blockAudienceData[item] !== "any";
            }

            if (item === "variable_lead") {
                return blockAudienceData[item] !== "any";
            }

            if (item === "user_time") {
                return blockAudienceData[item].is_active;
            }

            return true;
        }
    });

    return audienceExistAndNonDefaultFields.length > 0;
};

/**
 * Функция, которая возвращает массив
 * объектов с промежутками времени
 * (от 00:00 до 23:45) с интервалом в 15 минут
 * вида [{ label: '17:00', value: '17:00' }]
 *
 * @returns {ReactSelectOptionType[]}
 */
export const getTimeValuesForSelect = (): ReactSelectOptionType[] => {
    const arr = [];

    let i;
    let j;

    for (i = 0; i < 24; i++) {
        for (j = 0; j < 4; j++) {
            arr.push({
                label: i + ":" + (j === 0 ? "00" : 15 * j),
                value: i + ":" + (j === 0 ? "00" : 15 * j),
            });
        }
    }

    return arr;
};

export const getAgesForReactSelect = (
    ages: number[],
    type: string
): ReactSelectOptionType[] => {
    const agesArr = [
        {
            label: type === "from" ? "От" : "До",
            value: "any",
        },
    ];

    ages.forEach((val: any) => {
        agesArr.push({
            label: String(val),
            value: val,
        });
    });

    return agesArr;
};

export const parsePageIdFromHash = (url: string): string | boolean => {
    const matches = /page=(\w{1,})/gm.exec(url);

    if (!matches || !matches[1]) {
        return false;
    }

    return matches[1];
};

export const parseSubscriptionIdFromHash = (url: string): string | boolean => {
    const matches = /s=(\w{1,})/gm.exec(url);

    if (!matches || !matches[1]) {
        return false;
    }

    return matches[1];
};

export const getDestinationLinkFromVkAwayLink = (vkAwayLink: string): string => {
    const decodedLink = decodeURIComponent(vkAwayLink)
    return decodedLink.indexOf('?to=') > 0 ? decodedLink.split('?to=')[1] : ''
}

export const getHostnameFromLink = (link: string): string => {
    try {
        return (new URL(link)).hostname
    } catch (error) {
        return ''
    }
}
